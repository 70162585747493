import { MarketingConfig } from "@/types"

export const marketingConfig: MarketingConfig = {
  mainNav: [
    {
      title: "Featured On",
      href: "/featured",
    },
    {
      title: "Demo",
      href: "/#chat",
    },
    {
      title: "Pricing",
      href: "/#pricing",
    },
    {
      title: "FAQ",
      href: "/#faq",
    },
    {
      title: "Careers",
      href: "/careers",
    }
  ],
}